import React from 'react';
import { Component } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'   
import { Link } from 'react-router-dom'

import { PreviousTurnPlayer, NextTurnPlayer } from '../../actions/gameActions'

import biljartlogo from '../../images/biljart_logo.png';

import Navbar from './Navbar'

import TurnPlayer from './TurnPlayer';

import M from "materialize-css";

const cast = window.cast;

class Turn extends Component {    

    btnPreviousClick = () => {
        this.props.PreviousTurnPlayer(this.props.game);
    };    
    
    btnNextClick = () => {
        this.props.NextTurnPlayer(this.props.game);

    };    

    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.btnNextClick();
    }

    componentDidMount() {
        console.log("Turn componentDidMount");
        M.AutoInit();
        M.updateTextFields();
        // document.M.updateTextFields();
    }

    render() {
        let activePlayer1 = this.props.game.activePlayer == 1;
        let activePlayer2 = !activePlayer1;

        let navbarTitle = `Beurt ${this.props.game.activeTurn + 1}`;

        return (
            <>
                <Navbar title={navbarTitle} />
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <TurnPlayer player="1" active={activePlayer1} />
                        <TurnPlayer player="2" active={activePlayer2} />
                    
                        <div className="row">
                            <div className="col s4 left-align">
                                <a className="btn-floating btn-large waves-effect waves-light red" onClick={this.btnPreviousClick}><i className="material-icons right">arrow_back</i></a>
                            </div>
                            <div className="col s4 center-align">
                                <a className="btn-floating btn-large waves-effect waves-light black  modal-trigger" href="#modal1"><i className="material-icons right">equalizer</i></a>
                            </div>
                            <div className="col s4 right-align">
                                <a className="btn-floating btn-large waves-effect waves-light green" onClick={this.btnNextClick}><i className="material-icons right">arrow_forward</i></a>
                            </div>
                        </div>
                    </form>
                </div>
            </>
            );
        }
};

const mapStateToProps = state => {
    return {
        game: state.game
    }
}

export default connect(mapStateToProps, {PreviousTurnPlayer, NextTurnPlayer})(withRouter(Turn));