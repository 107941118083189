import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'   
import { Link } from 'react-router-dom'

import { SetTurn1, SetCurrentScore } from '../../actions/gameActions'

import biljartlogo from '../../images/biljart_logo.png';

class TurnPlayer extends Component {

    btnPlayerPointsMinus = (e) => {
        console.log("btnPlayerPointsMinus clicked");

        var activeTurn = this.props.game.turns[this.props.game.activeTurn];
        var currentScore = this.props.player == "1" ? this.props.game.activeTurn.player1Score : this.props.game.activeTurn.player2Score;
        currentScore--;

        this.props.SetCurrentScore(this.props.game, currentScore);
    };

    btnPlayerPointsAdd = (e) => {
        console.log("btnPlayerPointsAdd clicked");

        var activeTurn = this.props.game.turns[this.props.game.activeTurn];
        var currentScore = this.props.player == "1" ? activeTurn.player1Score : activeTurn.player2Score;
        console.log("currentscore1", currentScore);
        currentScore++;
        console.log("currentscore2", currentScore);

        this.props.SetCurrentScore(this.props.game, currentScore);
    };

    handleChange = (e) => {
        this.props.SetCurrentScore(this.props.game, e.target.value);
    };

    render() {
        console.log("rend");
        let disabled = !this.props.active ? '-disabled' : '';

        var playerName = this.props.player == "1" ? this.props.game.player1.name : this.props.game.player2.name;
        var target = this.props.player == "1" ? this.props.game.player1.target : this.props.game.player2.target;
        var average = this.props.player == "1" ? this.props.game.player1.average : this.props.game.player2.average;
        var totalScore = this.props.player == "1" ? this.props.game.player1.totalScore : this.props.game.player2.totalScore;
        var highestScore = this.props.player == "1" ? this.props.game.player1.highestScore : this.props.game.player2.highestScore;

        var percentageFilled = totalScore / target * 100;
        var strPercentageFilled = percentageFilled + '%';

        var activeTurn = this.props.game.turns[this.props.game.activeTurn];
        var currentScore = this.props.player == "1" ? activeTurn.player1Score : activeTurn.player2Score;

        return (
            <div className="row" style={{marginTop: 10}}>
                <div className="col s12">
                    <div className={`card card-background${disabled}`}>                            
                        <div className="card-content"  style={{margin: 0, padding: 0}}>
                            <div className="row" style={{margin: 0, paddingTop: 5}}>
                                <div className="row"  style={{margin: 0, padding: 0}}>
                                    <div className="col s8">
                                        <span className="card-title left-align">{playerName}</span>
                                    </div>
                                    <div className="col s4">
                                        <span className="card-title right-align right"><b>{totalScore}</b></span>
                                    </div>
                                </div>
                                <div className="row"  style={{margin: 0, padding: 0}}>
                                    <div className="col s12">
                                        <div className="progress">
                                            <div className="determinate" style={{width: strPercentageFilled}}></div>
                                        </div>   
                                    </div> 
                                </div>
                            </div>      
                            {
                                !this.props.active
                                    ?
                                    <div className="row valign-wrapper">
                                        <div className="col s12">
                                            <div className="input-field col s11 align-center" style={{ fontSize: 30 }}>
                                                {currentScore}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row valign-wrapper">
                                        <div className="col s1"></div>
                                        <div className="col s2">
                                            <a className="btn-floating btn-small waves-effect waves-light red" onClick={this.btnPlayerPointsMinus}><i className="material-icons">remove</i></a>
                                        </div>
                                        <div className="col s6">
                                            <div className="input-field col s11 align-center">
                                                <input autoFocus id="tbPlayer1Points" value={currentScore} name="PlayerPoints" type="number" className="validate center-align" style={{fontSize: 30}} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col s2">
                                            <a className="btn-floating btn-small waves-effect waves-light green" onClick={this.btnPlayerPointsAdd}><i className="material-icons">add</i></a>
                                        </div>
                                        <div className="col s1"></div>
                                    </div>
                            }
                        </div>
                        <div className={`card-content card-footer-background-color${disabled} card-footer-light-text valign-wrapper`} style={{padding: 0}}>
                            <div className={`col s4 card-footer-border-right${disabled}`}>
                                <p style={{paddingTop: '5px'}}><b>{target}</b></p>
                                <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>emoji_events</i></span>
                            </div>
                            <div className={`col s4 card-footer-border-right${disabled} card-footer-border-left`}>
                                <p style={{paddingTop: '5px'}}><b>{average}</b></p>
                                <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>all_inclusive</i></span>
                            </div>
                            <div className={`col s4 card-footer-border-left${disabled}`}>
                                <p style={{paddingTop: '5px'}}><b>{highestScore}</b></p>
                                <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>military_tech</i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
};

const mapStateToProps = state => {
    return {
        game: state.game
    }
}

export default connect(mapStateToProps, {SetCurrentScore})(withRouter(TurnPlayer));