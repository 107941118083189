import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import axios from 'axios';

import { gameApiUrl } from './utils/settings'

const initialState = {};

const persistedState = localStorage.getItem('reduxState') 
                       ? JSON.parse(localStorage.getItem('reduxState'))
                       : initialState;

const middleware = [thunk];

const store = createStore(
  rootReducer,
  persistedState,
  compose(
    applyMiddleware(...middleware)
    // , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    , typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
      ? a => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

store.subscribe(()=>{
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
});

let axiosConfig = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
  }
};



store.subscribe(() => {
  var axiosPostObject = {
    "gameGuid": store.getState().game.guid,
    "gameType": 100,
    "gameObject": JSON.stringify(store.getState())
  };

    // axios.get("http://www.onlinescorebord.nl/api/v1/game", /* axiosPostObject, */axiosConfig).then(resp => { console.log(resp) }).catch(error => { alert(error) });
    // axios.post("https://www.onlinescorebord.nl/game/createorupdate", axiosPostObject, axiosConfig).then(resp => { console.log(resp) }).catch(error => { console.log(error) });
    axios.post(`${gameApiUrl}/game/createorupdate`, axiosPostObject, axiosConfig).then(resp => { console.log(resp) }).catch(error => { console.log(error) });

});

export default store;