import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

import biljartlogo from '../../images/biljart_logo.png';

import Navbar from './Navbar'

import {SetPlayer1} from '../../actions/gameActions';

import M from "materialize-css";

class Player1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            target: this.props.target
        };

        // this.btnNextClick.bind(this);
    }

    componentDidMount() {

        M.AutoInit();
        M.updateTextFields();
       // document.M.updateTextFields();
    }

    btnNextClick = () => {
        console.log("btnNextClick");
        window.sendMessageToChromeCast();
        console.log("sendMessageToChromeCast done");
        this.props.SetPlayer1(this.state);
        // const history = this.props.history.push("/player2");
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = (e) =>
    {
        console.log("submit");
        // e.preventDefault();

        
    }

    btnHelpClick = () => {
        alert('help');
    };

    render() {
        return (
            <>
                <Navbar title="Speler 1" />
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div id="section_player1">
                            <div className="row">
                                <div className="col s12 center-align">
                                    <img className="d-block mx-auto mb-1" src={biljartlogo} alt="" height="100px" />
                                    <p className="lead">Om te beginnen met het spel kun je hier de namen en score invullen! Veel plezier!</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 center-align">
                                    <h5>Speler 1</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">account_circle</i>
                                    <input autoFocus id="tbPlayer1Name" type="text" className="validate" name="name" value={this.state.name} onChange={this.handleChange} />
                                    <label htmlFor="tbPlayer1Name">Naam</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">emoji_events</i>
                                    <input id="tbPlayer1Score" type="number" className="validate" name="target" value={this.state.target} onChange={this.handleChange} />
                                    <label htmlFor="tbPlayer1Score">Aantal punten</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6  left-align">
                                    <a href="#player2" className="btn green waves-effect waves-blue" id="btnHelpStep1" onClick={this.btnHelpClick}><i className="material-icons left">help</i>Help</a>
                                </div>
                                <div className="col s6  right-align">
                                    <a className="btn btn-primary-background-color waves-effect waves-blue" onClick={this.btnNextClick}><i className="material-icons right">arrow_right</i>Volgende</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
};

const mapStateToProps = state => {
    return {
        name: state.game.player1.name,
        target: state.game.player1.target
    }
}

export default connect(mapStateToProps, {SetPlayer1})(withRouter(Player1));