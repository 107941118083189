import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

import history from '../utils/history'

import '../styles/home.css';
import '../styles/bootstrap337.css';

import M from "materialize-css";

class Home extends Component {

    startBiljart = () => {
        history.push('/player1');
    };

    render() {
        return (
            <div style={{ height: '100vh' }}>
                <div className='split-pane col-xs-12 col-sm-6 uiux-side'>
                    <div>
                        <div className='text-content'>
                            <div className='big'>BILJART</div>
                        </div>
                        <a className='button' onClick={this.startBiljart}>
                            START!
                        </a>
                    </div>
                </div>
                <div className='split-pane col-xs-12 col-sm-6 frontend-side'>
                    <div>
                        <div className='text-content'>
                            <div className="bold"></div>
                            <div className='big'>DARTS</div>
                        </div>
                        <a className='button'>
                            COMING SOON!
                        </a>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect()(withRouter(Home));