import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/biljart/Navbar'
import { Router, Route, Switch } from 'react-router-dom'

import { connect } from 'react-redux'
import history from './utils/history';

import Home from './components/Home'

import Player1 from './components/biljart/Player1'
import Player2 from './components/biljart/Player2'
import Turn from './components/biljart/Turn'
import Turn2 from './components/biljart/Turn2'

import Watch from './components/watch/Watch'


function App() {

    return (
            <Router history={history}>
                <div className="App">

                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/player1">
                            <Player1 />
                        </Route>
                        <Route path="/player2">
                            <Player2 />
                        </Route>
                        <Route path="/turn/:turnNumber">
                            <Turn />
                        </Route>
                        <Route path="/turn2">
                            <Navbar />
                            <Turn2 />
                        </Route>
                        <Route path="/watch/:guid">
                            <Watch />
                        </Route>
                    </Switch>
                </div>
            </Router>
    );
}

export default connect()(App);
