import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'

import { SetTurn1 } from '../../actions/gameActions'

import biljartlogo from '../../images/biljart_logo.png';

class Turn extends Component {

    btnNextClick = () => {
        // SetTurn1(null);
        // const history = this.props.history.push("/player2");
    };

    render() {
        console.log("Render Turn");
        console.log(this);
        return (
            <div className="container">                
                <div className="row" style={{marginTop: 10}}>
                    <div className="col s12">
                        <div className="card card-background-disabled">                            
                            <div className="card-content"  style={{margin: 0, padding: 0}}>
                                <div className="row"  style={{margin: 0, padding: 0}}>
                                    <div className="col s8">
                                        <span className="card-title left-align">Speler 1</span>
                                    </div>
                                    <div className="col s4">
                                        <span className="card-title right-align right"><b>12</b></span>
                                    </div>
                                </div>
                                <div className="row"  style={{margin: 0, padding: 0}}>
                                    <div className="col s12">
                                        <div className="progress">
                                            <div className="determinate" style={{width: '60%'}}></div>
                                        </div>   
                                    </div> 
                                </div>           
                            </div>
                            <div className="card-content card-footer-background-color-disabled card-footer-light-text valign-wrapper" style={{padding: 0}}>
                                <div className="col s4 card-footer-border-right-disabled">
                                    <p style={{paddingTop: '5px'}}><b>45</b></p>
                                    <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>emoji_events</i></span>
                                </div>
                                <div className="col s4 card-footer-border-right-disabled">
                                    <p style={{paddingTop: '5px'}}><b>1,50</b></p>
                                    <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>all_inclusive</i></span>
                                </div>
                                <div className="col s4">
                                    <p style={{paddingTop: '5px'}}><b>15</b></p>
                                    <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>military_tech</i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: 10}}>
                    <div className="col s12">
                        <div className="card card-background">                            
                            <div className="card-content"  style={{margin: 0, padding: 0}}>
                                <div className="row" style={{margin: 0, paddingTop: 5}}>
                                    <div className="row"  style={{margin: 0, padding: 0}}>
                                        <div className="col s8">
                                            <span className="card-title left-align">Speler 2</span>
                                        </div>
                                        <div className="col s4">
                                            <span className="card-title right-align right"><b>12</b></span>
                                        </div>
                                    </div>
                                    <div className="row"  style={{margin: 0, padding: 0}}>
                                        <div className="col s12">
                                            <div className="progress">
                                                <div className="determinate" style={{width: '70%'}}></div>
                                            </div>   
                                        </div> 
                                    </div>
                                </div>                      
                                <div className="row valign-wrapper">
                                    <div className="col s1"></div>
                                    <div className="col s2">
                                        <a className="btn-floating btn-small waves-effect waves-light red"><i className="material-icons">remove</i></a>
                                    </div>
                                    <div className="col s6">
                                        <div className="input-field col s11 align-center">
                                            <input id="tbPlayer1Points" type="number" className="validate center-align" style={{fontSize: 30}} />
                                        </div>
                                    </div>
                                    <div className="col s2">
                                        <a className="btn-floating btn-small waves-effect waves-light green"><i className="material-icons">add</i></a>
                                    </div>
                                    <div className="col s1"></div>
                                </div>
                            </div>
                            <div className="card-content card-footer-background-color card-footer-light-text valign-wrapper" style={{padding: 0}}>
                                <div className="col s4 card-footer-border-right">
                                    <p style={{paddingTop: '5px'}}><b>45</b></p>
                                    <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>emoji_events</i></span>
                                </div>
                                <div className="col s4 card-footer-border-right card-footer-border-left">
                                    <p style={{paddingTop: '5px'}}><b>1,50</b></p>
                                    <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>all_inclusive</i></span>
                                </div>
                                <div className="col s4 card-footer-border-left">
                                    <p style={{paddingTop: '5px'}}><b>15</b></p>
                                    <span><i className="material-icons prefix" style={{fontSize: '18px', marginTop: '5px'}}>military_tech</i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s4 left-align">
                        <a className="btn-floating btn-large waves-effect waves-light red"><i className="material-icons right">arrow_back</i></a>
                    </div>
                    <div className="col s4 center-align">
                        <a className="btn-floating btn-large waves-effect waves-light black  modal-trigger" href="#modal1"><i className="material-icons right">equalizer</i></a>
                    </div>
                    <div className="col s4 right-align">
                        <a className="btn-floating btn-large waves-effect waves-light green" onClick={this.btnNextClick}><i className="material-icons right">arrow_forward</i></a>
                    </div>
                </div>
            </div>
            );
        }
};

export default withRouter(Turn);