import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

import axios from 'axios';

import { gameApiUrl } from '../../utils/settings';

import '../../styles/loader.css';

import M from "materialize-css";

class Watch extends Component {

    componentDidMount() {
        this.timer = setInterval(() => this.getItems(this.props.match.params.guid), 3000);
    }

    componentWillUnmount() {
        this.timer = null;
    }

    getItems = (guid) => {
        console.log("Watch: getItems");
        // var url = `https://www.onlinescorebord.nl/game/getbygameguid4?gameguid4=${guid}`;
        var url = `${gameApiUrl}/game/getbygameguid4?gameguid4=${guid}`;

        axios.get(url).then(resp => {
            console.log(resp);

            var strGameObject = resp.data.gameObject;
            console.log("strGameObject", strGameObject);

            var jsonGameObject = JSON.parse(strGameObject);
            console.log("jsonGameObject", jsonGameObject);

            this.setState(jsonGameObject);

            console.log(this);

        }).catch(resp => { console.log(resp) });
    }

    render() {

        console.log("Settings");
        console.log(gameApiUrl);

        let loaded = false;

        let turns = "";
        let player1 = null;
        let player2 = null;

        let player1Name = "";
        let player2Name = "";

        let player1TotalScore = 0;
        let player2TotalScore = 0;

        let player1PercentageFilled = 0;
        let player1StrPercentageFilled = `${player1PercentageFilled}%`;

        let player2PercentageFilled = 0;
        let player2StrPercentageFilled = `${player2PercentageFilled}%`;

        let player1Disabled = '-disabled';
        let player2Disabled = '-disabled';

        let player1Target = 0;
        let player2Target = 0;

        let player1Average = 0
        let player2Average = 0;

        let player1HighestScore = 0;
        let player2HighestScore = 0;

        let player1CurrentScore = 0;
        let player2CurrentScore = 0;

        let turnNumber = 0;

        if (this.state != null)
        {
            loaded = true;

            player1 = this.state.game.player1;
            player2 = this.state.game.player2;

            player1Name = player1.name;
            player2Name = player2.name;

            player1TotalScore = player1.totalScore;
            player2TotalScore = player2.totalScore;

            player1PercentageFilled = this.state.game.player1.totalScore / this.state.game.player1.target * 100;
            player1StrPercentageFilled = player1PercentageFilled + '%';

            player2PercentageFilled = this.state.game.player2.totalScore / this.state.game.player2.target * 100
            player2StrPercentageFilled = player2PercentageFilled + '%';

            if (this.state.game.activePlayer == 1) {
                player1Disabled = ''
            } else {
                player2Disabled = '';
            }

            player1Target = player1.target;
            player2Target = player2.target;

            player1Average = player1.average;
            player2Average = player2.average;

            player1HighestScore = player1.highestScore;
            player2HighestScore = player2.highestScore;

            turnNumber = this.state.game.activeTurn + 1;

            player1CurrentScore = this.state.game.turns[this.state.game.activeTurn].player1Score;
            player2CurrentScore = this.state.game.turns[this.state.game.activeTurn].player2Score;

            var reverseTurns = this.state.game.turns.reverse();
            turns = reverseTurns.map((turn, index) => {
                if (index > 0 || this.state.game.activeTurn < this.state.game.turns.length - 1) {
                    return (
                        <li className="collection-item">
                            <div className="container">
                                <div className="row" style={{ marginTop: 0 }}>
                                    <div className="col s4">
                                        {turn.player1Score}
                                    </div>
                                    <div className="col s4">
                                        {reverseTurns.length - index}
                                    </div>
                                    <div className="col s4">
                                        {turn.player2Score}
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                }
            });
        }


        return (
            <div className="container">
                <div id="loader" className="clock" style={{ display: !loaded ? 'block' : 'none' }}></div>
                <div className="row" style={{ display: loaded ? 'block' : 'none' }}>
                    <div className="col s12">
                        <div className={`card card-background${player1Disabled}`}>
                            <div className="card-content" style={{ margin: 0, padding: 0 }}>
                                <div className="row" style={{ margin: 0, paddingTop: 5 }}>
                                    <div className="row" style={{ margin: 0, padding: 0 }}>
                                        <div className="col s8">
                                            <span className="card-title left-align">{player1Name}</span>
                                        </div>
                                        <div className="col s4">
                                            <span className="card-title right-align right"><b>{player1TotalScore}</b></span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ margin: 0, padding: 0 }}>
                                        <div className="col s12">
                                            <div className="progress">
                                                <div className="determinate" style={{ width: player1StrPercentageFilled }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row valign-wrapper">
                                    <div className="col s12 align-center">
                                        <h5>{player1CurrentScore}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className={`card-content card-footer-background-color${player1Disabled} card-footer-light-text valign-wrapper`} style={{ padding: 0 }}>
                                <div className={`col s4 card-footer-border-right${player1Disabled}`}>
                                    <p style={{ paddingTop: '5px' }}><b>{player1Target}</b></p>
                                    <span><i className="material-icons prefix" style={{ fontSize: '18px', marginTop: '5px' }}>emoji_events</i></span>
                                </div>
                                <div className={`col s4 card-footer-border-right${player1Disabled} card-footer-border-left`}>
                                    <p style={{ paddingTop: '5px' }}><b>{player1Average}</b></p>
                                    <span><i className="material-icons prefix" style={{ fontSize: '18px', marginTop: '5px' }}>all_inclusive</i></span>
                                </div>
                                <div className={`col s4 card-footer-border-left${player1Disabled}`}>
                                    <p style={{ paddingTop: '5px' }}><b>{player1HighestScore}</b></p>
                                    <span><i className="material-icons prefix" style={{ fontSize: '18px', marginTop: '5px' }}>military_tech</i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: loaded ? 'block' : 'none' }}>
                    <div className="col s12">
                        {turnNumber}
                    </div>
                </div>
                <div className="row" style={{ display: loaded ? 'block' : 'none' }}>
                    <div className="col s12">
                        <div className={`card card-background${player2Disabled}`}>
                            <div className="card-content" style={{ margin: 0, padding: 0 }}>
                                <div className="row" style={{ margin: 0, paddingTop: 5 }}>
                                    <div className="row" style={{ margin: 0, padding: 0 }}>
                                        <div className="col s8">
                                            <span className="card-title left-align">{player2Name}</span>
                                        </div>
                                        <div className="col s4">
                                            <span className="card-title right-align right"><b>{player2TotalScore}</b></span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ margin: 0, padding: 0 }}>
                                        <div className="col s12">
                                            <div className="progress">
                                                <div className="determinate" style={{ width: player2StrPercentageFilled }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row valign-wrapper">
                                    <div className="col s12 align-center">
                                        <h5>{player2CurrentScore}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className={`card-content card-footer-background-color${player2Disabled} card-footer-light-text valign-wrapper`} style={{ padding: 0 }}>
                                <div className={`col s4 card-footer-border-right${player2Disabled}`}>
                                    <p style={{ paddingTop: '5px' }}><b>{player2Target}</b></p>
                                    <span><i className="material-icons prefix" style={{ fontSize: '18px', marginTop: '5px' }}>emoji_events</i></span>
                                </div>
                                <div className={`col s4 card-footer-border-right${player2Disabled} card-footer-border-left`}>
                                    <p style={{ paddingTop: '5px' }}><b>{player2Average}</b></p>
                                    <span><i className="material-icons prefix" style={{ fontSize: '18px', marginTop: '5px' }}>all_inclusive</i></span>
                                </div>
                                <div className={`col s4 card-footer-border-left${player2Disabled}`}>
                                    <p style={{ paddingTop: '5px' }}><b>{player2HighestScore}</b></p>
                                    <span><i className="material-icons prefix" style={{ fontSize: '18px', marginTop: '5px' }}>military_tech</i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    /* <div className="row">
                        <div className="col s6">
                            <div className={`card card-background`}>
                                <div>{player1?.name}</div>
                                <div><h4>{player1Score}</h4></div>
                            </div>
                        </div>
                        <div className="col s6">
                            <div className={`card card-background`}>
                                <div>{player2?.name}</div>
                                <div><h4>{player2Score}</h4></div>
                            </div>
                        </div>
                    </div> */ }
                <ul className="collection with-header" style={{ display: loaded ? 'block' : 'none' }}>
                    <li className="collection-header" style={{ padding: '10px 20px 10px 30px' }}>
                        <div className="row">
                            <div className="col s4">
                                {player1?.name}
                            </div>
                            <div className="col s4">
                                Beurt
                            </div>
                            <div className="col s4">
                                {player2?.name}
                            </div>
                        </div>
                    </li>
                    {turns}
                </ul>
            </div>
        );
    }
};

export default connect()(withRouter(Watch));