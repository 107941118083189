import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import biljartlogo from '../../images/biljart_logo.png';

import Navbar from './Navbar'

import {SetPlayer2} from '../../actions/gameActions';

import M from "materialize-css";


class Player2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            target: this.props.target
        };

        // this.btnNextClick.bind(this);
    }

    componentDidMount() {

        M.AutoInit();
        M.updateTextFields();
       // document.M.updateTextFields();
    }

    btnNextClick = () => {
        console.log("player2");
        this.props.SetPlayer2(this.state);
        // const history = this.props.history.push("/player2");
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        return (
            <>
                <Navbar title="Speler 2" />
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div id="section_player2">
                            <div className="row">
                                <div className="col s12 center-align">
                                    <img className="d-block mx-auto mb-1" src={biljartlogo} alt="" height="100px" />
                                    <p className="lead">Om te beginnen met het spel kun je hier de namen en score invullen! Veel plezier!</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 center-align">
                                    <h5>Speler 2</h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">account_circle</i>
                                    <input autoFocus id="tbPlayer2Name" type="text" className="validate"  name="name" value={this.state.name} onChange={this.handleChange} />
                                    <label htmlFor="tbPlayer2Name">Naam</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">emoji_events</i>
                                    <input id="tbPlayer2Score" type="number" className="validate" name="target" value={this.state.target} onChange={this.handleChange} />
                                    <label htmlFor="tbPlayer2Score">Aantal punten</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 left-align">
                                    <a className="waves-effect waves-light btn btn-cancel-background-color" id="btnPlayer2Previous" to="/"><i className="material-icons left">arrow_left</i>Vorige</a>
                                </div>
                                <div className="col s6 right-align">
                                    <a className="waves-effect waves-light btn btn-primary-background-color" id="btnPlayer2Next" onClick={this.btnNextClick}><i className="material-icons right">arrow_right</i>Start</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
            );
        }
};

const mapStateToProps = state => {
    return {
        name: state.game.player2.name,
        target: state.game.player2.target
    }
}

export default connect(mapStateToProps, {SetPlayer2})(withRouter(Player2));