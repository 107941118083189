import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { NewGame } from '../../actions/gameActions';

const cast = window.cast;

class Navbar extends Component{
   

    btnNewGameClick = () => {
        if (window.confirm("Weet u het zeker?"))
        {
            this.props.NewGame();
        }
    }; 

    btnShareClick = () => {
        if (navigator.share) {

            var text = `${this.props.game.player1.name} - ${this.props.game.player2.name}`; 
            var guid4 = this.props.game.guid.substring(0, 4);
            navigator
                .share({
                    title: "Online Scorebord",
                    text: text,
                    url: `https://www.onlinescorebord.nl/watch/${guid4}`,
                })
                .then(() => {
                    console.log('Successfully shared');
                })
                .catch(error => {
                    console.error('Something went wrong sharing the blog', error);
                });
        }
    };

    btnCastClick = () => {
        // alert("Cast button clicked");
        // console.log(cast);
        //alert("Cast button clicked");
        //console.log("Cast button clicked");
        //let session = cast.framework.CastContext.getInstance().getCurrentSession();

        //console.log(session);

        //if (session == null) {
        //    console.log("Geen cast session");
        //    return;
        //}

        ////var result = session.sendMessage('urn:x-cast:com.google.cast.sample.helloworld', jsonInput);
        //var result = session.sendMessage('urn:x-cast:nl.onlinescorebord.game', 'testen');

        //console.log(result);
    };

    //btnCastClick = useCallback(async () => {
    //    console.log("castbutton clicked");
    //    console.log(this);

    //    if (this.cast.castReceiver) {
    //        console.log("cast.castReceiver");
    //        await this.cast.handleConnection();
    //    }
    //}, [this.cast.castReceiver, this.cast.handleConnection])

    componentDidMount = () => {
        console.log("ComponentDidMount");
        // console.log(window.cast);
        console.log("ComponentDidMountEnd");

        //var context = window.cast.framework.CastContext.getInstance();
        //context.addEventListener(
        //    window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        //    function (event) {
        //        switch (event.sessionState) {
        //            case window.cast.framework.SessionState.SESSION_STARTED:
        //                console.log("Navbar.render cast.framework.SessionState.SESSION_STARTED:");
        //                break;
        //            case window.cast.framework.SessionState.SESSION_RESUMED:
        //                console.log("Navbar.render cast.framework.SessionState.SESSION_STARTED:");
        //                break;
        //            case window.cast.framework.SessionState.SESSION_ENDED:
        //                console.log('CastContext: CastSession disconnected');
        //                // Update locally as necessary
        //                break;
        //        }
        //    })
    };


    render() 
    {
        // console.log(cast);
        //var context = cast.framework.CastContext.getInstance();
        //context.addEventListener(
        //    cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        //    function (event) {
        //        switch (event.sessionState) {
        //            case cast.framework.SessionState.SESSION_STARTED:
        //                console.log("Navbar.render cast.framework.SessionState.SESSION_STARTED:");
        //                break;
        //            case cast.framework.SessionState.SESSION_RESUMED:
        //                break;
        //            case cast.framework.SessionState.SESSION_ENDED:
        //                console.log('CastContext: CastSession disconnected');
        //                // Update locally as necessary
        //                break;
        //        }
        //    })

        let shareButtonVisible = this.props.game?.player1?.name != "" && this.props.game?.player2?.name != "";

        var activeTurn = this.props.game.activeTurn;

        return (
            <header>
                <div className="navbar-fixed">
                    <nav>
                        <div className="nav-wrapper" style={{backgroundColor: "#25318E"}}>
                            <a href="#" className="brand-logo center">{this.props.title}</a>
                            <ul className="right">
                                <li><a href="#" id="asd"><google-cast-launcher id="castbutton" onClick={this.btnCastClick}></google-cast-launcher></a></li>
                                { /* <li><a href="#" id="asd" onClick={this.btnCastClick}><i className="material-icons">cast</i></a></li> 
                                <li><CastButton /></li> */ }
                                <li id="btnShare"><a className="right" onClick={this.btnShareClick} style={shareButtonVisible ? {} : { display: 'none' }} ><i className="material-icons">share</i></a></li>
                            </ul>

                            <a href="#" id="mobile-menu" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                        </div>
                    </nav>
                </div>

                <ul className="sidenav" id="mobile-demo">
                    <li className="center-align"><b>Menu</b></li>
                    <li><a href="#" onClick={this.btnNewGameClick}><i className="material-icons">add</i>Nieuw spel</a></li>
                    <li><a href="#"><i className="material-icons">equalizer</i>Statistieken</a></li>
                    <li><a href="#"><i className="material-icons">share</i>Delen</a></li>
                    <li></li>
                    <li>Spel: {this.props.game.guid}</li>
                </ul>

                <div id="tap-target-help1" className="tap-target green darken-3" data-target="btnShare" /* style="color: #FFF" */>
                    <div className="tap-target-content">
                        <h5>Deel en cast</h5>
                        <p className="right">Deel en laat vrienden live meekijken! Daarnaast kun je met je ChromeCast je TV ombouwen naar scorebord!</p>
                        <a href="#" id="btnHelpStep2" className="right" /* style="color: #FFF; text-decoration: underline;" */>Volgende</a>
                    </div>
                </div>

                <div id="tap-target-help2" className="tap-target green darken-3" data-target="mobile-menu" /* style="color: #FFF"*/>
                    <div className="tap-target-content">
                        <h5>Menu</h5>
                        <p>Klik op het menu om een nieuw spel te beginnen, en meer!</p>
                        <a href="#" id="btnHelpClose" /* style="color: #FFF; text-decoration: underline;"*/>Sluiten</a>
                    </div>
                </div>
            </header>

        );
    }
};

const mapStateToProps = state => {
    return {
        game:  state.game
    }
}

export default connect(mapStateToProps, {NewGame})(withRouter(Navbar));