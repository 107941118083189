import history from '../utils/history'

export const SET_PLAYER1 = "SET_PLAYER1";
export const SET_PLAYER2 = "SET_PLAYER2";
export const SET_CURRENTSCORE = "SET_CURRENTSCORE";
export const SET_CURRENTTURN = "SET_CURRENTTURN";
export const SET_CURRENTPLAYER = "SET_CURRENTPLAYER";
export const NEXT_TURNPLAYER = "NEXT_TURNPLAYER";
export const PREVIOUS_TURNPLAYER = "PREVIOUS_TURNPLAYER";
export const NEW_GAME = "NEW_GAME";

export const SetPlayer1 = (player) => {
    console.log("gameActions: setPlayer1");
    // window.sendMessageToChromeCast();
    history.push("/player2");

    return {
        type: SET_PLAYER1,
        payload: {
            name: player.name,
            target: player.target,
            average: 0,
            totalScore: 0,
            highestScore: 0
        }
    }
};

export const SetPlayer2 = (player) => {
    console.log("gameActions: setPlayer2");
    history.push("/turn/1/1");

    return {
        type: SET_PLAYER2,
        payload: {
            name: player.name,
            target: player.target,
            average: 0,
            totalScore: 0,
            highestScore: 0
        }
    }
};

export const SetCurrentScore = (game, currentScore) => {
    currentScore = parseInt(currentScore);

    if (game.activePlayer == 1) {
        game.turns[game.activeTurn].player1Score = currentScore;
    }
    else {
        game.turns[game.activeTurn].player2Score = currentScore;
    }

    return {
        type: SET_CURRENTSCORE,
        payload: game
    }
};

export const PreviousTurnPlayer = (game) => {
    if (game.activePlayer == 1 && game.activeTurn > 0)
    {
        game.activePlayer = 2;
        game.activeTurn = game.activeTurn - 1;
    } 
    else 
    {
        game.activePlayer = 1;
    }

    // game.currentScore = game.activePlayer == 1 ? game.turns[game.activeTurn].player1Score : game.turns[game.activeTurn].player2Score;

    history.push("/turn/" + (game.activeTurn + 1) + "/" + (game.activePlayer));

    return {
        type: PREVIOUS_TURNPLAYER,
        payload: 
            game
    }
};

export const NextTurnPlayer = (game) => {
    
    if (game.activePlayer == 1)
    {
        //game.turns[game.activeTurn].player1Score = parseInt(game.currentScore);

        if (game.turns[game.activeTurn].player1Score > game.player1.highestScore)
        {
            game.player1.highestScore = game.turns[game.activeTurn].player1Score;
        }
        game.activePlayer = 2;
    } else {
        //game.turns[game.activeTurn].player2Score = parseInt(game.currentScore);
        game.activePlayer = 1;

        if (game.turns[game.activeTurn].player2Score > game.player2.highestScore)
        {
            game.player2.highestScore = game.turns[game.activeTurn].player2Score;
        }

        game.activeTurn++;
    }

    game.currentScore = 0;

    let player1Score = 0;
    let player2Score = 0;
    for (let i = 0; i < game.turns.length; i++)
    {
        player1Score += game.turns[i].player1Score;
        player2Score += game.turns[i].player2Score;
    }

    game.player1.totalScore = player1Score;
    game.player2.totalScore = player2Score;

    game.player1.average = game.turns.length > 0 ? (game.player1.totalScore / game.turns.length).toFixed(2) : 0;

    var player2TurnLength = game.turns.length;
    if (game.activePlayer == 2) // activePlayer BECOMES 2
    {
        player2TurnLength -= 1;
    }
    game.player2.average = player2TurnLength > 0 ? (game.player2.totalScore / player2TurnLength).toFixed(2) : 0;

    // console.log(sumPlayer1Score);

    if (game.turns.length <= game.activeTurn)
    {
        var turnNumber = game.turns.length + 1;
        game.turns.push({
            number: turnNumber,
            player1Score: 0,
            player2Score: 0
        })
    }

    history.push("/turn/" + (game.activeTurn + 1) + "/" + (game.activePlayer));

    return {
        type: NEXT_TURNPLAYER,
        payload: 
            game
    }
};

export const NewGame = () =>
{
    history.push("/");

    return {
        type: NEW_GAME,
        payload: null
    }
};

// export { SetPlayer1, SetTurn1 };