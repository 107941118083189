import { SET_PLAYER1, SET_PLAYER2, SET_CURRENTSCORE, PREVIOUS_TURNPLAYER, NEXT_TURNPLAYER, NEW_GAME } from '../actions/gameActions';

const initialState = {
    guid: uuidv4(),
    player1: {
        name: "",
        target: 0,
        average: 0,
        totalScore: 0,
        highestScore: 0 
    },
    player2: {
        name: "",
        target: 0,
        average: 0,
        totalScore: 0,
        highestScore: 0
    },
    turns: [
        {
            number: 1,
            player1Score: 0,
            player2Score: 0
        }
    ],   
    activeTurn: 0,
    activePlayer: 1
}

function uuidv4() {

    let result = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );

    return result;
    
  }

const gameReducer = (state = initialState, action) => {
    let result = {};

    switch (action.type) {
        case SET_PLAYER1:

            result = {
                ...state,
                player1: action.payload
            }

            break;
        case SET_PLAYER2:

            result = {
                ...state,
                player2: action.payload
            }

            break;
        case SET_CURRENTSCORE:
            result = {
                ...action.payload
            }

            break;
        case PREVIOUS_TURNPLAYER:

            result = {
                ...action.payload
            };

            break;
        case NEXT_TURNPLAYER:

            result = {
                ...action.payload
            };

            break;
        case NEW_GAME:
            result = {
                ...initialState,
                guid: uuidv4()
            }
            break;
        default:
            result = state;
            break;
    }

    window.setGameGuid(result.guid);

    return result;
}
  
export default gameReducer;